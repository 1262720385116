<template>
  <div id="footerHome" class="footer_home w-full pb-4 z-10 flex flex-col text-center">
    <h4 class="text-dilo-blue leading-4">{{ $store.getters.texts("home.footerText") }}</h4>
    <h4 class="text-dilo-blue font-bold text-sm mb-2 md:mb-4">1951-2021</h4>
    <button @click="e =>$emit('simplefade', e)" class="btn btn_orange mx-auto sm:mb-3">
      {{ $store.getters.texts("home.footerButton") }}
    </button>
    <img src="../assets/img/infinity.png" class="h-9 mx-auto hidden sm:block">
  </div>
</template>

<script>
export default { name: "FooterHome" };
</script>
