import { State, ResponseObject } from "@/vuex";

export default {
  activateLoading(state: State, payload: number) { state.loadingArr[payload] = true; },
  closePopup(state: State) {
    state.currentWidgetHandle = "";
    state.showPopup = false;
  },
  changeBgColor(state: State, payload: string) { state.bgColor = payload; },
  language(state: State, payload: string) { state.language = payload; },
  loadingComplete(state: State) {
    state.loadingArr = [];
    state.showPopup = true;
  },
  response(state: State, payload: Array<ResponseObject>) { state.response = payload; },
  scrollPosStories(state: State, payload: number) { state.scrollPosStories = payload; },
  showCurtain(state: State, payload: boolean) { state.curtainVisible = payload; },
  widgetHandle(state: State, payload: string) { state.currentWidgetHandle = payload; },
};
