<template>
  <div id="home" class="relative font-thin">

    <section id="opener" class="flex flex-col justify-end relative pt_head">
      <picture>
        <source media="(max-width: 479px)" srcset="/index.php/download_file/231/0">
        <img src="/index.php/download_file/230/0" class="w-full h-full top-0 absolute object-cover object-top" alt="70 years">
      </picture>
      <div class="relative flex flex-col mb-16 pb-2">
        <h1 class="small_h1 hero_h1 mx-auto leading-none text-center uppercase text-white">
          <span class="font-bold">One Vision.</span><br>
          Zero Emissions.
        </h1>
        <button @click="simpleFade" class="btn btn_orange mx-auto mt-8 mb-12 sm:mb-3">
          {{ $store.getters.texts("home.footerButton") }}
        </button>
        <img src="../assets/img/infinity.png" class="h-9 mx-auto hidden sm:block">
      </div>
    </section>

    <section id="vision" class="w-full bg-white">
      <div class="fluid_content_container small mx-auto h-full md:pl-4 pt_head flex flex-col">
        <div class="flex flex-col my-auto">
          <h1 class="small_h1 text-black uppercase font-bold px-3">one<br>vision.</h1>
          <div class="mt-3 lg:mt-5 px-3 text-lg sm:text-xl leading-tight">
            {{ $store.getters.texts("home.quoteText") }}
          </div>
          <div class="mt-3 lg:mt-5 font-bold text-lg sm:text-xl px-3">
            {{ $store.getters.texts("home.vision") }} {{ $store.getters.texts("home.noEmission") }}
          </div>
          <div class="mt-3 lg:mt-5 px-3 text-lg sm:text-xl">{{ $store.getters.texts("home.thanks") }}</div>
          <div class="text-lg sm:text-xl -mt-1.5 px-3">
            Christian Scheller
          </div>
          <div class="text-xs px-3">{{ $store.getters.texts("home.authorTitle") }}</div>
        </div>
        <FooterHome @simplefade="simpleFade"></FooterHome>
      </div>
    </section>

    <section id="emission" class="w-full bg-black">
      <div class="fluid_content_container small mx-auto h-full md:pl-4 pt_head flex flex-col">
        <div class="flex flex-col my-auto">
          <h1 class="small_h1 text-gray-200 uppercase px-3 sm:px-7 ">zero<br> emissions.</h1>
          <div class="text-gray-400 mt-5 px-3 sm:px-7 text-2xl sm:text-3xl leading-none">
            {{ $store.getters.texts("home.for70years") }}
          </div>
          <div class="text-gray-400 mt-5 lg:mt-7 px-3 sm:px-7 text-2xl sm:text-3xl leading-none">
            {{ $store.getters.texts("home.noChange") }}
          </div>
        </div>
        <div>
          <FooterHome @simplefade="simpleFade"></FooterHome>
          <hr class="border-dilo-gray-dark">
          <Footer></Footer>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import FooterHome from "@/components/FooterHome";
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(CSSPlugin, ScrollTrigger);

export default {
  name: "Home",
  components: { Footer, FooterHome },
  methods: {
    initGsap() {
      ScrollTrigger.create({
        trigger: "#opener",
        endTrigger: "#vision",
        start: "top top",
        end: () => window.innerHeight * 0.25,
        pin: true,
        pinSpacing: false
      });
      gsap.from("#opener", {
        scrollTrigger: {
          trigger: "#vision",
          toggleActions: "restart complete none reverse",
          start: "top 75%",
          end: "center center",
          scrub: true
        },
        y: window.innerHeight * 0.25
      });

      ScrollTrigger.create({
        trigger: "#vision",
        endTrigger: "#emission",
        start: "top top",
        end: () => window.innerHeight * 1.5,
        pin: true,
        pinSpacing: false
      });
      gsap.from("#vision", {
        scrollTrigger: {
          trigger: "#emission",
          toggleActions: "restart complete none reverse",
          start: "top center",
          end: "center center",
          scrub: true
        },
        y: window.innerHeight * 0.5
      });

      this.$nextTick(() => window.dispatchEvent(new Event("resize")));
    },
    simpleFade(event) {
      this.$store.commit("showCurtain", true);

      let that = this;
      setTimeout(function() {
        that.$router.push({ name: "Stories", params: { lang: that.$store.state.language === 'en' ? 'en' : '' }  });
      }, 200);

      let tileViewportOffset = event.target.getBoundingClientRect();
      let tTop = tileViewportOffset.top;
      let tLeft = tileViewportOffset.left;

      let curtainViewportOffset = document.getElementById("curtain").getBoundingClientRect();
      let cTop = curtainViewportOffset.top;
      let cLeft = curtainViewportOffset.left;

      gsap.fromTo("#curtain", {
        x: tLeft, y: tTop - cTop,
        width: event.target.offsetWidth, height: event.target.offsetHeight,
        backgroundColor: "rgb(195, 206, 215)",
        // alpha: 80
      }, {
        x: 0, y: -cTop,
        width: window.innerWidth - 20, height: window.innerHeight,
        backgroundColor: "rgb(195, 206, 215)",
        // alpha: 100,
        duration: .5,
        ease: "strong.out",
        onComplete: this.$store.commit, onCompleteParams: [ "showCurtain", false ]
      });
    }
  },
  mounted() {
    this.$nextTick(this.initGsap);
    this.$store.commit("changeBgColor", "black");
  },
};
</script>
