<template>
  <div id="footer" class="footer pb-4 flex flex-col text-center text-white">
    <div class="flex justify-center">
      <a class="text-center hover:text-dilo-blue" target="_blank" :href="$store.getters.texts('footer.imprintLink')">
        {{ $store.getters.texts("footer.imprint") }}
      </a>
      <span class="px-2 opacity-50">|</span>
      <a class="text-center hover:text-dilo-blue" target="_blank" :href="$store.getters.texts('footer.privacyLink')">
        {{ $store.getters.texts('footer.privacy') }}
      </a>
    </div>

    <div class="flex justify-center">
      <a class="hover:text-dilo-blue" :href="$store.getters.texts('general.diloLink')" target="_blank">© DILO GmbH</a>
    </div>
  </div>
</template>

<script>
export default { name: "Footer" };
</script>
