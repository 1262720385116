import { createStore } from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

import texts from "../assets/texts.json";

export default createStore({
  state: {
    bgColor: "black",
    currentWidgetHandle: "",
    curtainVisible: false,
    init: true,
    language: 'de',
    loadingArr: [],
    response: [],
    scrollPosStories: -1,
    showPopup: false,
    texts: texts,
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
});
