export function getStories(commit: any, dispatch: any, getters: any, state: any, router: any) {
  let naviJson = "https://dilo70.eu/index.php/api/navigation/" + state.language;
  if (window.location.hash.substr(1)) {
    naviJson = "https://dilo70.eu/index.php/api/navigation/" + state.language + "/" + window.location.hash.substr(1);
  }
  fetch(naviJson)
    .then(function(response) {
      if (response.ok) return response.json();
      else return [];
    })
    .then(function(data) {
      let cwNumber = getters.currentWidgetNumber;
      let cwHandle = state.currentWidgetHandle;
      commit("response", data);

      let langURL = state.language === "en" ? "en" : "";
      if (cwHandle || cwNumber) {
        let newWidgetHandle = "";
        for (let i = 0; i < data.length; i++) {
          if ((data[i].fieldnumber === cwNumber) || (data[i].handle === cwHandle)) {
            newWidgetHandle = data[i].handle;
            break;
          }
        }
        dispatch("widgetHandle", newWidgetHandle);
        router.replace({ name: "Moment", params: { handle: newWidgetHandle, lang: langURL } });
      } else if (router.currentRoute._value.params !== langURL) {
        router.replace({ name: router.currentRoute._value.name, params: { lang: langURL } });
      }
    })
    .catch(function(e) {
      console.log(e);
    });
}
