<template>
  <div id="curtain" class="fixed z-30" v-show="$store.state.curtainVisible"></div>
  <div
      class="background_container"
      :class="bgContainerClasses"
      :style="style"
  >
    <Header></Header>
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import FooterHome from "@/components/FooterHome.vue";

export default defineComponent({
  name: "App",
  components: { FooterHome, Footer, Header },
  computed: {
    bgContainerClasses() {
      let result = this.$route.name === "Home" ? "duration-300" : "duration-0";
      if (this.$route.name === "Stories") result += " background_container_image";
      return result;
    },
    style() { return "background-color: " + this.$store.state.bgColor + ";"; }
  },
});
</script>

