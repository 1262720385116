import { getStories } from "@/assets/js/helperfunctions";
import router from "@/router";


export default {
// @ts-ignore
  language({ commit, dispatch, getters, state }, payload: string) {
    if ((payload !== state.language) || state.init) {
      commit("language", payload);
      getStories(commit, dispatch, getters, state, router);
      state.init = false;
    }
  },
  widgetHandle(context: any, payload: string) { context.commit("widgetHandle", payload); }
};
