import { State } from "@/vuex";
import { GetterTree } from "vuex";

export default <GetterTree<State, any>>{
  currentWidget(state, getters) { return state.response[getters.currentWidgetID]; },
  currentWidgetID(state) {
    for (let i = 0; i < state.response.length; i++) if (state.response[i].handle === state.currentWidgetHandle) return i;
  },
  currentWidgetNumber(state, getters) { return getters.currentWidget ? getters.currentWidget.fieldnumber : ""; },
  texts: (state) => (path: string) => {
    if (path && state.texts) {
      let pathArray = [];
      if (path.includes(".")) pathArray = path.split(".");
      else pathArray[0] = path;

      let result = state.texts[state.language];
      for (let i = 0; i < pathArray.length; i++) {
        // @ts-ignore
        if (typeof result === "object") result = result[pathArray[i]];
        else break;
      }
      if (typeof result !== "string") result = path;
      return result;
    }
  }
};
