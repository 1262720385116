<template>
  <div id="header" class="w-full absolute sm:sticky top-0 z-30 h-0">
    <router-link class="home_link" :to="{ name: 'Home', params: { lang: $store.state.language === 'en' ? 'en' : '' } }">
      <img src="../assets/img/dilo_logo.svg" class="top-0 left-0 h-full w-full absolute">
      <img src="../assets/img/dilo_logo_70.svg" class="top-0 left-0 h-full w-full absolute logo2">
    </router-link>
    <div class="language_switch">
      <div :class="{ 'active': $store.state.language === 'de' }" @click="$store.dispatch('language', 'de')">
        DE
      </div>
      <span class="separator"></span>
      <div :class="{ 'active': $store.state.language === 'en' }" @click="$store.dispatch('language', 'en')">
        EN
      </div>
    </div>

    <div class="flex header_right">

      <router-link
          :to="{ name: 'Stories', params: { lang: $store.state.language === 'en' ? 'en' : '' } }"
          v-if="$route.name === 'Moment'"
          class="p-2 md:p-0"
      >
        <div class="relative h-5 w-5 md:h-7 md:w-7 mx-auto transition-transform duration-200 cross transform rotate-45">
          <div class="absolute rounded bg-white horizontal"></div>
          <div class="absolute rounded bg-white vertical"></div>
        </div>
      </router-link>
    </div>

    <div class="grid social_links floating_action_button" :class="{ 'show': showDropdown }" @click="shareClick">
      <div class="dropdown flex flex-col">
        <a class="flex social_link facebook" @click="fbClick">
          <i class="fab fa-facebook"></i>
        </a>

        <a class="flex social_link twitter" @click="twitterClick">
          <i class="fab fa-twitter"></i>
        </a>

        <a class="flex social_link xing" @click="xingClick">
          <i class="fab fa-xing"></i>
        </a>

        <a class="flex social_link linkedin" @click="linkedInClick">
          <i class="fab fa-linkedin"></i>
        </a>

        <a class="flex social_link email" @click="emailClick">
          <i class="fal fa-envelope"></i>
        </a>
      </div>
      <div class="floating_action_button_inner">
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M15.6262 13.7254C14.6447 13.7254 13.7554 14.1402 13.1372 14.7995L7.00442 11.3699C7.10202 11.0615 7.15083 10.7371 7.15083 10.4021C7.15083 10.0672 7.0966 9.7428 7.00442 9.4344L13.1372 6.00475C13.7554 6.6641 14.6447 7.07884 15.6262 7.07884C17.4969 7.07884 19.0152 5.58469 19.0152 3.75555C19.0152 1.92109 17.4969 0.432251 15.6262 0.432251C13.7554 0.432251 12.2371 1.92641 12.2371 3.75555C12.2371 4.02673 12.2696 4.29259 12.3347 4.5425L6.10971 8.01468C5.49697 7.4351 4.66733 7.07884 3.75093 7.07884C1.88017 7.07884 0.361877 8.56768 0.361877 10.4021C0.361877 12.2366 1.8856 13.7254 3.75093 13.7254C4.66733 13.7254 5.49697 13.3692 6.10971 12.7843L12.3293 16.2618C12.2642 16.517 12.2317 16.7776 12.2317 17.0487C12.2317 18.8832 13.75 20.372 15.6207 20.372C17.4915 20.372 19.0098 18.8779 19.0098 17.0487C19.0152 15.2196 17.4969 13.7254 15.6262 13.7254Z"
              fill="black"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";

gsap.registerPlugin(CSSPlugin);

export default {
  name: "Header",
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    fbClick() {
      window.open(
          "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(location.href),
          "facebook-share-dialog",
          "width=625,height=430"
      );
      return false;
    },
    linkedInClick() {
      window.open(
          "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(location.href),
          "linkedin-share-dialog",
          "width=626,height=436"
      );
      return false;
    },
    emailClick() {
      window.open("mailto:?subject=70%20Jahre%20DILO!&body=" + encodeURIComponent(location.href));
      return false;
    },
    twitterClick() {
      window.open(
          "https://twitter.com/intent/tweet?text=70%20Jahre%20DILO%20%23dilo%20%23%20" + encodeURIComponent(location.href),
          "twitter-share-dialog",
          "width=626,height=436"
      );
      return false;
    },
    xingClick() {
      window.open(
          "https://www.xing.com/app/user?op=share&url=" + encodeURIComponent(location.href),
          "xing-share-dialog",
          "width=626,height=436"
      );
      return false;
    },
    shareClick() {
      /* Auflösung vom iPad, größere Geräte müssen halt zweimal tippen */
      if (navigator.share && (((window.innerWidth < 769) && (window.innerHeight < 1025)) || ((window.innerWidth < 1025) && (window.innerHeight < 769)))) {
        console.log("navigator.share vorhanden");
        navigator.share({
          title: "DILO 70 Jahre weitersagen",
          url: encodeURIComponent(location.href)
        }).then(() => {
          console.log("Thanks for sharing!");
        })
            .catch(console.error);
      } else {
        this.showDropdown = !this.showDropdown;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      gsap.fromTo(".logo2", {
        opacity: "0%",
        duration: 2,
      }, {
        opacity: "100%",
        duration: 2,
        repeat: -1,
        repeatDelay: 10,
        yoyo: true
      });

    }, 10000);
  }
};
</script>
