import { createRouter, createWebHistory } from "vue-router";
import Home from "../pages/Home.vue";
import Home_en from "../pages/Home.vue";
import Store from "../store/index";
import { nextTick } from "vue";

const DEFAULT_TITLE = {
  de: "DILO : 70 Jahre - 70 Einblicke",
  en: "DILO : 70 Years - 70 Impressions"
};


const routes = [
  {
    path: "/:lang?",
    name: "Home",
    component: Home,
  },
  {
    path: "/:lang?/stories",
    name: "Stories",
    component: () => import(/* webpackChunkName: "stories" */ "../pages/Stories.vue"),
    children: [
      {
        path: "/:lang?/stories/:handle",
        name: "Moment",
        props: true,
        component: () => import(/* webpackChunkName: "moment" */ "../components/Widget.vue")
      },
    ]
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory("/"),
  routes
});

router.beforeEach((to, from, next) => {
  let lang = (to.path.includes("/en/") || to.path.endsWith("/en")) ? "en" : "de";
  if (typeof from.name === "undefined") Store.dispatch("language", lang).then();

  if (from.name === "Stories") {
    if (typeof to.name === "string" && to.name.includes("Moment")) Store.commit("scrollPosStories", window.scrollY);
    else Store.commit("scrollPosStories", 0);
    window.scrollTo(0, 0);
  }

  if (to.name === "Home") {
    window.scrollTo(0, 0);
    document.documentElement.classList.add("overflow-x-hidden");
  }
  if (to.name === "Stories") {
    Store.commit("changeBgColor", "#c3ced7");
    let scrollPos = Store.state.scrollPosStories;
    if (scrollPos === -1) scrollPos = 0;
    let count = 0;
    let int = setInterval(function() {
      window.scrollTo(0, scrollPos);
      if (count > 25) clearInterval(int);
      else count++;
    }, 1);
  }
  if (to.name === "Moment") Store.dispatch("widgetHandle", to.params.handle).then();

  next();
});
router.afterEach((to, from) => {
  let lang = Store.state.language === "en" ? "en" : "";
  if (typeof to.name === "string" && (to.params.lang !== lang)) router.replace({ name: to.name, params: { lang: lang } }).then();

  if (from.name === "Home") document.documentElement.classList.remove("overflow-x-hidden");
  if (from.name === "Moment" && to.name !== "Moment") Store.commit("closePopup");

  let titleLang = lang === "en" ? "en" : "de";
  if (to.name === "Moment") {
    if (Store.getters.currentWidget) {
      Store.commit("changeBgColor", Store.getters.currentWidget.styles.bgcolor);
      // @ts-ignore
      nextTick(function() { document.title = Store.getters.currentWidget.name || DEFAULT_TITLE[titleLang]; }).then();
    }
    // @ts-ignore
  } else nextTick(function() { document.title = DEFAULT_TITLE[titleLang]; }).then();
  if (to.name === "Stories") nextTick(function() {
    (document.getElementById("sprache") as HTMLInputElement).value = titleLang;
  }).then();
});

export default router;
